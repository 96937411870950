<template lang="pug">
.moreinfo(
  ref="moreBlock"
  :class="{'moreinfo--active': !isMore}"
)
  slot(name="moreBlock")
  .morebtn(:class="{'morebtn--hide': !isMore}" @click="isMore = false")
    .morebtn__text.d-flex.justify-content-center.justify-content-lg-start
      .d-block.d-lg-inline {{ $t('shared.readMore') }}
      img.morebtn__img(:src="require(`@/assets/images/about-arrow.svg`)")
</template>

<script>
export default {
  name: "MoreInfoRead",
  components: {},
  data() {
    return {
      isMore: true,
      isCheck: false,
    };
  },
  methods: {
    checkMore(check) {
      if (!this.isCheck) {
        const height = this.$refs.moreBlock.clientHeight;
        this.isMore = height >= 154;
      }
      if (check) {
        this.isCheck = check;
      }
    },
  },
  mounted() {
    this.checkMore(true);
  },
  updated: function () {
    this.$nextTick(() => {
      this.checkMore(true);
    });
  },
};
</script>

<style lang="sass" scoped>
*
  transition: max-height 1s linear

.moreinfo
  position: relative
  width: 100%
  max-height: 154px
  overflow: hidden
  margin-bottom: 7px

  &--active
    height: auto
    max-height: 1500px
    overflow: auto
    +rwd-md
      max-height: 2000px

.morebtn
  position: absolute
  display: flex
  justify-content: flex-start
  align-items: flex-end
  top: 0
  right: 0
  width: 100%
  height: 100%
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 55%, #FFFFFF 92%)

  &--hide
    display: none

  &__text
    padding: 4px 0 1px
    font-size: 12px
    line-height: 12px
    cursor: pointer
    color: #fff
    background-color: #000
    +rwd-md
      margin: 0 8px
      width: 100%
      text-align: center
      padding: 11px 0

  &__img
    width: 6px
    height: auto
    margin-left: 5px
</style>
